// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shareLinkButton--kxpsG {
  --bo-ui-button-svg-height: 24px;
  --bo-ui-button-svg-width: 24px;
}
.shareLinkBodalButtons--yB2GH {
  flex-direction: column;
  align-content: center;
  justify-content: center!important;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/shareLinkButton/shareLinkButton.module.less"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,8BAAA;AACF;AAEA;EACE,sBAAA;EACA,qBAAA;EACA,iCAAA;EACA,gBAAA;EACA,qBAAA;AAAF","sourcesContent":[".shareLinkButton {\n  --bo-ui-button-svg-height: 24px;\n  --bo-ui-button-svg-width: 24px;\n}\n\n.shareLinkBodalButtons {\n  flex-direction: column;\n  align-content: center;\n  justify-content: center!important;\n  margin-top: 1rem;\n  margin-bottom: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareLinkButton": `shareLinkButton--kxpsG`,
	"shareLinkBodalButtons": `shareLinkBodalButtons--yB2GH`
};
export default ___CSS_LOADER_EXPORT___;
